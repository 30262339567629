/*
  Only required is to display none the .menu-icon
*/

.menu-icon {
    display: none;
    cursor: pointer;
    padding: 0;
    height: auto;
    width: auto;
    font-size: 0px;
}
.menu-icon::after{display: none;}
.menu-icon .menu-box {
    width: 40px;
    height: 25px;
    margin-right: 0;
    padding: 0;
    display: inline-block;
}
.menu-icon .menu-box span {
    width: 100%;
    height: 4px;
    margin-bottom: 6px;
    background: $dark_blue;
    display: block;
    border-radius: 2px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.menu-icon.active span:nth-child(1) {
    transform: rotate(45deg) translateY(8px) translateX(6px);
    -webkit-transform: rotate(45deg) translateY(8px) translateX(6px);
}
/*.menu-icon.hover:not(.active) span:nth-child(1) {
    transform: rotate(90deg) translateX(8px);
    -webkit-transform: rotate(90deg) translateX(8px);
}*/
.menu-icon.active span:nth-child(2) {
    opacity: 0;
}
.menu-icon.active span:nth-child(3) {
    transform: rotate(-45deg) translateY(-7px) translateX(6px);
    -webkit-transform: rotate(-45deg) translateY(-7px) translateX(6px);
}
/*.menu-icon.hover:not(.active) span:nth-child(3) {
    transform: rotate(-90deg) translateX(6px);
    -webkit-transform: rotate(-90deg)  translateX(6px);
}*/
.enumenu_ul .caret {
    color: #fff;
    margin-left: 7px;
}
.enumenu_ul {
    padding: 0px;
    list-style: none;
}
.enumenu_ul li {
    /*display: inline-block;*/
	float: left;
    vertical-align: top;
    padding-bottom: 23px;
    position: relative;
    padding: 0;
    letter-spacing: 0;
}
.enumenu_ul li:before{display: none;}
.enumenu_ul > li ul {
    display: none
}
.enumenu_ul ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 35px;
    list-style: none;
    position: relative;
    @include breakpoints(large){
        margin: 0 0 0 15px;
    }
    @include breakpoints(tablet-portrait){
        margin: 0;
        width: 100%;
        text-align: left;
    }
}
/*.enumenu_ul.desk ul {
    top: 100%;
    z-index: 999;
    list-style: none;
    left: 0px;
    background: #000000;
    position: absolute;
    min-width: 150px;
}*/
/*Sub menu styles*/

.menu.submenu-ul-level1 li a {
    background: rgba(31, 26, 107, 0.8);
    color: #fff;
    padding-left: 90px;
}
.enumenu_ul.desk li:hover > ul {
    display: block;
    opacity: 1;
}
.enumenu_ul.desk .sb-menu .sb-menu {
    left: 100%;
    top: 0;
}
.enumenu_ul ul li a {
    display: block;
    color: #002f86;
    font-size: 16px;
    padding: 0;
    text-transform: uppercase;
    font-family: $proxima;
    font-weight: $bold;
    letter-spacing: 0.6px;
    @include transition(color, 0.5s, ease-in-out);
    @include breakpoints(tablet-portrait){
        padding: rem-calc(15);
        border-top: rem-calc(1) solid $dark_blue;
        &:hover{
            
        }
    }
}
.enumenu_ul ul li a:hover{
    color: $yellow;
}
.enumenu_ul ul li.current-menu-item a{
    color: $yellow;
    background-color: transparent;
}
.menu .is-active > a{
    color: $yellow;
    background-color: transparent;
}
.enumenu_ul .arrow {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 999
}
@media (max-width: 991px) {
    .enumenu_ul{
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background-color: $white;
        height: 100vh;
        overflow: scroll;
        padding-bottom: rem-calc(150);
    }
    header {
        padding-top: 0;
    }
    .enumenu_ul {
        display: none
    }
    .menu-block {
        height: auto;
    }
    .menu-icon {
        display: block
    }
    .services-detail a.pba-detail {
        border-bottom: 2px solid #d3d9da;
    }
    .enumenu_ul > li {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .enumenu_ul > li > a {
        display: block;
        text-align: center;
        padding: 8px
    }
    .enumenu_ul {
        width: 100%;
    }
    .enumenu_ul > li ul {
        background: #D1D1D1;
    }
    .enumenu_ul > li ul a {
        text-align: center;
        display: block;
        text-align: center;
        color: #000;
        padding: 5px 0;
    }
    /* Push Menu */
    body.menuslide_push {
        position: relative;
    }
    body.menuslide_push.slidemenuLeft {
        left: 0;
        transition: left 0.5s;
        -webkit-transition: left 0.5s;
        -moz-transition: left 0.5s;
        -ms-transition: left 0.5s;
        -o-transition: left 0.5s;
    }
    body.menuslide_push.slidemenuRight {
        right: 0;
        transition: right 0.5s;
        -webkit-transition: right 0.5s;
        -moz-transition: right 0.5s;
        -ms-transition: right 0.5s;
        -o-transition: right 0.5s;
    }
    body.slidemenuRight .enumenu_ul {
        right: -300px;
    }
    body.slidemenuRight.menu-open .enumenu_ul {
        right: 0;
    }
    body.slidemenuLeft .enumenu_ul {
        left: -300px;
    }
    body.slidemenuLeft.menu-open .enumenu_ul {
        left: 0;
    }
    body.menuslide_push.slidemenuLeft.menu-open {
        left: 300px
    }
    body.menuslide_push.slidemenuRight.menu-open {
        right: 300px
    }
    body.menuslide_push .enumenu_ul,
    body.menuOverlap .enumenu_ul {
        background: #000000 none repeat scroll 0 0;
        display: block;
        height: 100%;
        max-height: 100%;
        width: 100%;
        overflow: auto;
        position: fixed;
        transition: all 0.5s ease 0s;
        max-width: 300px;
        top: 0;
    }
}