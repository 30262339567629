body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: $proxima;
}

.grid-container {
    padding: rem-calc(0 15);
}

.sticky-header {
    .header_inner {
        padding: rem-calc(8 68 6 74);
        @include breakpoints(x-large) {
            padding: rem-calc(12 15 12 15);
        }
        .logo {
            /*padding: rem-calc(12 0 12 74);*/
            img {
                max-width: 75%;
            }
        }
        .main_nav {
            /*padding: rem-calc(20 68 20 0);*/
        }
    }
    .site-header {
        box-shadow: 0px 0px 13px -4px #3d3834;
        -ms-box-shadow: 0px 0px 13px -4px #3d3834;
        -o-box-shadow: 0px 0px 13px -4px #3d3834;
    }
    @include breakpoints(tablet-portrait) {
        .header_inner {
            .logo {
                img {
                    max-width: 65%;
                }
            }
        }
    }
}

.site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
}

.header_inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(12 68 12 74);
    .logo {
        /*padding: rem-calc(12 0 0 74);*/
    }
    .main_nav {
        /*padding: rem-calc(34 68 0 0);*/
        .menu-main-menu-container {
            display: inline-block;
            vertical-align: top;
            padding: rem-calc(11 0 0 0);
        }
        .language_div {
            display: inline-block;
            vertical-align: top;
            margin: rem-calc(0 0 0 21);
        }
    }
    @include breakpoints(x-large) {
        padding: rem-calc(12 15 12 15);
    }
    @include breakpoints(large) {
        .main_nav {
            .language_div {
                margin: rem-calc(0 0 0 10);
            }
        }
    }
    @include breakpoints(tablet-portrait) {
        .logo {
            img {
                max-width: 65%;
            }
        }
        .main_nav {
            .menu-main-menu-container {
                padding: rem-calc(0 0 0 0);
                width: 100%;
                display: block;
            }
            .language_div {
                margin: rem-calc(0);
                padding: rem-calc(15);
                border-top: rem-calc(1) solid $dark_blue;
                display: block;
                a {
                    display: inline-block;
                }
            }
        }
    }
}

.language_div {
    a {
        font-family: $proxima;
        font-weight: $bold;
        font-size: rem-calc(14);
        line-height: rem-calc(16);
        color: $gray;
        padding: rem-calc(10 37 7 21);
        border: rem-calc(1) solid $gray;
        display: block;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: rem-calc(5) solid transparent;
            border-right: rem-calc(5) solid transparent;
            border-top: rem-calc(8) solid $gray;
            top: rem-calc(13);
            right: rem-calc(22);
        }
    }
}

.main_banner_sec {
    padding: rem-calc(32 0 0 0);
    .main_banner_div {
        height: rem-calc(710);
        width: 100%;
        position: relative;
        display: table;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
        }
        .banner_video {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            .slider-video {
                height: 100%;
                object-fit: cover;
                width: 100%;
                background-size: cover;
            }
            .video-iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .banner_content {
            position: relative;
            z-index: 9;
            max-width: rem-calc(1030);
            margin: 0 auto;
            text-align: center;
            padding: rem-calc(33 15 0 15);
            .banner_title {
                font-weight: $light;
                color: $dark_blue;
                font-size: rem-calc(60);
                line-height: rem-calc(72);
                letter-spacing: rem-calc(-0.3);
                margin: rem-calc(0 0 5 0);
            }
            p {
                font-size: rem-calc(23);
                line-height: rem-calc(34);
            }
            .scroll_down {
                width: 0;
                height: 0;
                border-left: rem-calc(13) solid transparent;
                border-right: rem-calc(13) solid transparent;
                border-top: rem-calc(13) solid $blue;
                display: inline-block;
                position: relative;
                top: rem-calc(16);
                left: rem-calc(-1);
            }
        }
    }
    @include breakpoints(small) {
        .main_banner_div {
            height: rem-calc(350);
            .banner_content {
                padding: rem-calc(90 15);
                .banner_title {
                    font-size: rem-calc(22);
                    line-height: rem-calc(28);
                    font-weight: $bold;
                }
                p {
                    font-size: rem-calc(16);
                    line-height: rem-calc(20);
                }
            }
        }
    }
    @include breakpoints(extra-small) {
        padding: rem-calc(20 0 0 0);
    }
}

.inner_banner_sec {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: rem-calc(96 0 300 0);
    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.73;
        background: -moz-linear-gradient(top, rgba(31, 61, 124, 1) 55%, rgba(55, 82, 138, 1) 65%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(top, rgba(31, 61, 124, 1) 55%, rgba(55, 82, 138, 1) 65%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to bottom, rgba(31, 61, 124, 1) 55%, rgba(55, 82, 138, 1) 65%, rgba(255, 255, 255, 0) 100%);
    }
    .inner_banner_main {
        position: relative;
        z-index: 1;
        .inner_banner_content {
            text-align: center;
            padding: rem-calc(0 40);
            .page_title {
                font-weight: $light;
                font-size: rem-calc(60);
                line-height: rem-calc(68);
                color: $white;
            }
            p {
                color: $white;
                font-weight: $light;
                font-size: rem-calc(23);
                line-height: rem-calc(34);
            }
        }
    }
    &.inner_banner_small {
        padding: rem-calc(96 0 102 0);
        &:before {
            background: none;
            background-color: $blue;
        }
    }
    @include breakpoints(tablet-portrait) {
        padding: rem-calc(70 0 70 0);
        &.inner_banner_small {
            padding: rem-calc(70 0 70 0);
        }
        .inner_banner_main {
            .inner_banner_content {
                padding: rem-calc(0);
            }
        }
    }
    @include breakpoints(x-small) {
        padding: rem-calc(40 0 40 0);
        &.inner_banner_small {
            padding: rem-calc(40 0 40 0);
        }
        .inner_banner_main {
            .inner_banner_content {
                .page_title {
                    font-size: rem-calc(40);
                    line-height: rem-calc(48);
                }
                p {
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                }
            }
        }
    }
}

.service_block_sec {
    .service_block_inner {
        padding: rem-calc(104 0 0 0);
        .service_block_content {
            text-align: center;
            padding: rem-calc(0 15);
            h1 {
                margin: rem-calc(0 0 13 0);
            }
        }
        .service_repeater {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: rem-calc(84 0 0 0);
            align-items: flex-end;
            .service_box {
                width: 33.33%;
                text-align: center;
                margin: rem-calc(0 0 55 0);
                a {
                    display: block;
                    backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: none;
                    }
                }
                h3 {
                    font-weight: $bold;
                    font-size: rem-calc(20);
                    line-height: rem-calc(24);
                    text-transform: uppercase;
                    color: $blue;
                }
                .service_img_wrap {
                    width: rem-calc(260);
                    height: rem-calc(260);
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    margin: rem-calc(28 0 0 0);
                    z-index: 9;
                    .service_img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        @include transition(all, 0.5s, ease-in-out);
                        background-position: center center;
                        backface-visibility: hidden;
                        -webkit-backface-visibility: hidden;
                        border-radius: 100%;
                        -webkit-border-radius: 100%;
                        -moz-border-radius: 100%;
                        -ms-border-radius: 100%;
                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background-color: $blackcolor;
                            opacity: 0;
                            @include transition(all, 0.5s, ease-in-out);
                            border-radius: 100%;
                            -webkit-border-radius: 100%;
                            -moz-border-radius: 100%;
                            -ms-border-radius: 100%;
                        }
                    }
                }
                a {
                    transition: none;
                    &:hover {
                        .service_img_wrap {
                            border-radius: 100%;
                            overflow: hidden;
                            z-index: 9;
                            .service_img {
                                @include transform-scale(1.1);
                                backface-visibility: hidden;
                                &:before {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait) {
        .service_block_inner {
            .service_repeater {
                .service_box {
                    .service_img_wrap {
                        width: rem-calc(220);
                        height: rem-calc(220);
                    }
                    h3 {
                        font-size: rem-calc(18);
                        line-height: rem-calc(22);
                    }
                }
            }
        }
    }
    @include breakpoints(small) {
        .service_block_inner {
            padding: rem-calc(40 0 0 0);
            .service_block_content {
                h1 {
                    font-size: rem-calc(24);
                    line-height: rem-calc(30);
                }
                p {
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                }
            }
            .service_repeater {
                margin: rem-calc(40 0 0 0);
                .service_box {
                    width: 50%;
                }
            }
        }
    }
    @include breakpoints(x-small) {
        .service_block_inner {
            .service_repeater {
                .service_box {
                    width: 100%;
                    margin: rem-calc(0 0 30 0);
                    .service_img_wrap {
                        width: rem-calc(250);
                        height: rem-calc(250);
                        margin: rem-calc(10 0 0 0);
                    }
                }
            }
        }
    }
}

.feature_project_sec {
    background-color: $dark_gray;
    margin: rem-calc(47 0 0 0);
    padding: rem-calc(85 0 70 0);
    .feature_project_content {
        text-align: center;
        h2 {
            margin: rem-calc(0 0 14 0);
        }
        p {
            a {
                color: $blue;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    height: rem-calc(1);
                    left: 0;
                    right: 0;
                    bottom: rem-calc(2);
                    background-color: $blue;
                    @include transition(all, 0.5s, ease);
                }
                &:hover {
                    color: $yellow;
                    &:before {
                        background-color: $yellow;
                    }
                }
            }
        }
    }
    .feature_project_slider {
        margin: rem-calc(51 0 0 0);
        .owl-prev {
            font-size: 0;
            position: absolute;
            left: rem-calc(-48);
            top: rem-calc(-26);
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: rem-calc(14) solid transparent;
            border-right: rem-calc(14) solid $blue;
            border-bottom: rem-calc(14) solid transparent;
        }
        .owl-next {
            font-size: 0;
            position: absolute;
            right: rem-calc(-48);
            top: rem-calc(-26);
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: rem-calc(14) solid transparent;
            border-left: rem-calc(14) solid $blue;
            border-bottom: rem-calc(14) solid transparent;
        }
    }
    .view_projects {
        margin: rem-calc(30 0 0 0);
        text-align: center;
        .btn {
            background-color: $yellow;
            padding: rem-calc(15 35 13 34);
            vertical-align: top;
            font-family: $proxima;
            font-weight: $bold;
            font-size: rem-calc(18);
            line-height: rem-calc(22);
            text-transform: uppercase;
            color: $white;
            display: inline-block;
            letter-spacing: rem-calc(0.4);
            &:hover {
                background-color: $blackcolor;
                color: $white;
            }
        }
    }
    @include breakpoints(x-large) {
        .feature_project_slider {
            padding: rem-calc(0 30);
            .owl-next {
                right: 0;
            }
            .owl-prev {
                left: 0;
            }
        }
    }
}

.feature_project_list {
    display: flex;
    flex-wrap: wrap;
    min-height: rem-calc(475);
    .feature_project_img {
        width: 65%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .feature_project_detail {
        width: 35%;
        background-color: $white;
        padding: rem-calc(92 40 40 34);
        h6 {
            font-weight: $bold;
            font-size: rem-calc(20);
            line-height: rem-calc(24);
            text-transform: uppercase;
            color: $blue;
            margin: rem-calc(0 0 5 0);
        }
        span {
            font-weight: $light;
            font-size: rem-calc(21);
            line-height: rem-calc(30);
            color: $light_gray;
            display: block;
            padding: rem-calc(0 0 35 0);
        }
    }
    @include breakpoints(tablet-portrait) {
        .feature_project_img {
            width: 55%;
        }
        .feature_project_detail {
            width: 45%;
            padding: rem-calc(30 30 0 30);
        }
    }
    @include breakpoints(small) {
        min-height: inherit;
        .feature_project_img {
            width: 100%;
            padding-top: 50%;
        }
        .feature_project_detail {
            width: 100%;
            h6 {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
            }
            span {
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                padding: rem-calc(0 0 20 0);
            }
        }
    }
}

.who_we_serve_sec {
    padding: rem-calc(95 0 95 0);
    .who_we_serve_content {
        text-align: center;
        h2 {
            margin: rem-calc(0 0 15 0);
        }
    }
    .client_logo_slider {
        margin: rem-calc(42 0 0 0);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .owl-stage {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .client_logo_div {
            text-align: center;
            img {
                width: auto;
                display: inline-block;
            }
        }
    }
    @include breakpoints(small) {
        padding: rem-calc(40 0 40 0);
    }
}

.about_block_sec {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: rem-calc(124 0 156 0);
    .about_block_main {
        position: relative;
        background-color: rgba(255, 255, 255, 0.95);
        max-width: 69%;
        padding: rem-calc(80 90 81 0);
        &:before {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            left: -79.95%;
            width: 80%;
            background-color: rgba(255, 255, 255, 0.95);
        }
        h2 {
            margin: rem-calc(35 0 15 0);
        }
    }
    @include breakpoints(large) {
        padding: rem-calc(80 0 80 0);
        .grid-container {
            padding: 0;
        }
        .about_block_main {
            max-width: 100%;
            padding: rem-calc(30 30 30 30);
        }
    }
}

.footer_main {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: rem-calc(0 0 40 0);
    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $blue;
        opacity: 0.73;
    }
    .footer_inner {
        position: relative;
        z-index: 1;
        .consultation_sec {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: rem-calc(112 0 62 0);
            .consultation_content {
                width: 44%;
                padding: rem-calc(12 0 0 0);
                h2 {
                    color: $white;
                    font-size: rem-calc(47);
                    line-height: rem-calc(52);
                    margin: rem-calc(0 0 15 0);
                }
                p {
                    color: $white;
                    font-size: rem-calc(23);
                    line-height: rem-calc(33);
                    letter-spacing: rem-calc(0.1);
                }
            }
            .footer_form_sec {
                width: 47%;
            }
        }
        .location_contact {
            text-align: center;
            p {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                color: $white;
                font-weight: $regural;
                strong {
                    font-weight: $bold;
                    padding-right: rem-calc(10);
                }
                a {
                    &:hover {
                        color: $yellow;
                    }
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                .phone_num {
                    padding-left: rem-calc(30);
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "//";
                        left: rem-calc(8);
                        top: 0;
                        font-weight: $light;
                    }
                }
            }
        }
        .footer_social {
            padding: rem-calc(15 0 0 0);
            text-align: center;
            ul {
                li {
                    padding: rem-calc(0 11);
                    display: inline-block;
                    vertical-align: middle;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .copy_text {
            padding: rem-calc(6 0 0 0);
            text-align: center;
            p {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                color: $white;
                letter-spacing: rem-calc(0.1);
                a {
                    &:hover {
                        color: $yellow;
                    }
                }
                .extra_link {
                    padding-left: rem-calc(20);
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "|";
                        left: rem-calc(9);
                        top: 0;
                    }
                }
            }
        }
        .career_footer {
            max-width: 82%;
            margin: 0 auto;
            text-align: center;
            padding: rem-calc(103 0 94 0);
            h2 {
                font-weight: $light;
                font-size: rem-calc(47);
                line-height: rem-calc(57);
                color: $white;
                margin: rem-calc(0 0 12 0);
            }
            p {
                font-weight: $regural;
                font-size: rem-calc(23);
                line-height: rem-calc(33);
                color: $white;
            }
            .contact_btn {
                font-weight: $bold;
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                color: $white;
                display: inline-block;
                vertical-align: top;
                padding: rem-calc(14 23 11 23);
                margin: rem-calc(37 0 0 0);
                background-color: $yellow;
                text-transform: uppercase;
                &:hover {
                    background-color: $blackcolor;
                }
            }
        }
        .contact_footer {
            padding: rem-calc(94 0 0 0);
            .contact_footer_content {
                text-align: center;
                padding: rem-calc(0 20 0 20);
                h1 {
                    font-weight: $light;
                    font-size: rem-calc(60);
                    line-height: rem-calc(70);
                    color: $white;
                }
                p {
                    font-weight: $light;
                    font-size: rem-calc(23);
                    line-height: rem-calc(33);
                    color: $white;
                }
            }
            .form_main {
                max-width: rem-calc(545);
                margin: 0 auto;
                padding-top: rem-calc(57);
                padding-bottom: rem-calc(130);
                .gform_wrapper input {
                    height: rem-calc(45);
                }
                .gform_wrapper select {
                    height: rem-calc(45);
                }
                .gform_wrapper .gform_footer {
                    text-align: center;
                    padding: rem-calc(10 0 0 0);
                }
                .gform_footer input[type=submit] {
                    height: auto;
                    background-color: $yellow;
                    &:hover {
                        background-color: $blackcolor;
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait) {
        .footer_inner {
            .location_contact {
                p {
                    .phone_num {
                        display: block;
                        padding: rem-calc(10 0 0 0);
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(small) {
        .footer_inner {
            .consultation_sec {
                padding: rem-calc(40 0 30 0);
                .consultation_content {
                    width: 100%;
                    padding: rem-calc(0 0 30 0);
                    h2 {
                        font-size: rem-calc(32);
                        line-height: rem-calc(34);
                    }
                    p {
                        font-size: rem-calc(18);
                        line-height: rem-calc(26);
                    }
                }
                .footer_form_sec {
                    width: 100%;
                }
            }
            .career_footer {
                max-width: 100%;
                padding: rem-calc(50 0 70 0);
                h2 {
                    font-size: rem-calc(37);
                    line-height: rem-calc(47);
                }
                p {
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                }
                .contact_btn {
                    font-size: rem-calc(18);
                    line-height: rem-calc(22);
                    padding: rem-calc(12 23 11 23);
                    margin: rem-calc(25 0 0 0);
                }
            }
            .contact_footer {
                padding: rem-calc(94 0 0 0);
                .contact_footer_content {
                    text-align: center;
                    padding: rem-calc(0 0 0 0);
                    h1 {
                        font-size: rem-calc(50);
                        line-height: rem-calc(60);
                    }
                    p {
                        font-size: rem-calc(20);
                        line-height: rem-calc(28);
                    }
                }
                .form_main {
                    padding-bottom: rem-calc(60);
                }
            }
        }
    }
    @include breakpoints(x-small) {
        .footer_inner {
            .copy_text {
                p {
                    .extra_link {
                        display: block;
                        padding: 0;
                        a {
                            display: inline-block;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.content_block_sec {
    padding: rem-calc(147 0 157 0);
    position: relative;
    .content_block_bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: 112.8% 0%;
        z-index: -1;
    }
    .content_logo {
        padding: rem-calc(24 0 0 12);
    }
    .content_block_text {
        padding: rem-calc(0 40 0 0);
        h2 {
            color: $dark_blue;
            margin: rem-calc(0 0 15 0);
        }
        p {}
    }
    @include breakpoints(large) {
        padding: rem-calc(80 0 80 0);
        .content_block_text {
            padding: rem-calc(0 0 0 20);
        }
    }
    @include breakpoints(tablet-medium) {
        .cell {
            width: 100%;
        }
        .content_logo {
            padding: rem-calc(0 0 30 0);
        }
        .content_block_text {
            padding: rem-calc(0 0 0 0);
        }
        .content_block_bg {
            background-position: 40% 0%;
            background-size: cover;
        }
    }
    @include breakpoints(x-small) {
        padding: rem-calc(30 0 30 0);
    }
}

.content_column_sec {
    background-color: $gray_84;
    padding: rem-calc(94 0 84 0);
    position: relative;
    .content_column_bg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: left bottom;
    }
    .content_column_inner {
        max-width: rem-calc(765);
        margin-left: auto;
        position: relative;
        z-index: 1;
        .medium-6 {
            &:nth-child(odd) {
                padding-left: rem-calc(24);
            }
        }
        .content_column_heading {
            h2 {
                color: $white;
                margin: rem-calc(0 0 91 0);
            }
        }
        .content_column_text {
            margin: rem-calc(0 0 58 0);
            h6 {
                font-size: rem-calc(21);
                line-height: rem-calc(25);
                color: $white;
                font-weight: $bold;
                text-transform: uppercase;
                margin: rem-calc(0 0 8 0);
            }
            p {
                color: $white;
                font-weight: $light;
                font-size: rem-calc(20);
                line-height: rem-calc(30);
            }
        }
    }
    @include breakpoints(small) {
        padding: rem-calc(40 0 20 0);
        .content_column_inner {
            .content_column_heading {
                h2 {
                    margin: rem-calc(0 0 35 0);
                }
            }
            .content_column_text {
                margin: rem-calc(0 0 20 0);
            }
        }
    }
    @include breakpoints(x-small) {
        .content_column_inner {
            .medium-6 {
                &:nth-child(odd) {
                    padding-left: rem-calc(0);
                }
            }
        }
    }
}

.zigzag_main {
    .zigzag_block {
        position: relative;
        .side_img_div {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .zigzag_content {
            padding: rem-calc(104 100 101 100);
            max-width: 50%;
            h2 {
                line-height: rem-calc(52);
                margin: rem-calc(0 0 14 0);
                color: $dark_blue;
            }
            p {
                color: $dark_blue;
            }
            ul {
                margin: rem-calc(3 0 0 0);
                li {
                    font-weight: $light;
                    font-size: rem-calc(21);
                    line-height: rem-calc(26);
                    color: $dark_blue;
                    padding: rem-calc(0 0 5 20);
                    letter-spacing: 0;
                    &:before {
                        background-color: $dark_blue;
                        width: rem-calc(4);
                        height: rem-calc(4);
                        left: rem-calc(3);
                        top: rem-calc(11);
                    }
                }
            }
            .btn {
                background-color: $blackcolor;
                padding: rem-calc(15 35 11 34);
                margin: rem-calc(20 0 0 0);
                vertical-align: top;
                font-family: $proxima;
                font-weight: $bold;
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                text-transform: uppercase;
                color: $white;
                display: inline-block;
                letter-spacing: rem-calc(0.8);
                &:hover {
                    background-color: $yellow;
                    color: $white;
                }
            }
        }
        &:nth-child(even) {
            .side_img_div {
                left: 0;
                right: auto;
            }
            .zigzag_content {
                margin-left: auto;
                margin-right: rem-calc(0);
                padding: rem-calc(131 100 126 100);
            }
        }
    }
    &.left {
        .zigzag_block {
            .side_img_div {
                left: 0;
                right: auto;
            }
            .zigzag_content {
                margin-left: auto;
                margin-right: rem-calc(0);
                padding: rem-calc(131 100 126 100);
            }
            &:nth-child(even) {
                .side_img_div {
                    right: 0;
                    left: auto;
                }
                .zigzag_content {
                    padding: rem-calc(104 100 101 100);
                    margin: 0;
                }
            }
        }
    }
    @include breakpoints(large) {
        .zigzag_block {
            .zigzag_content {
                padding: rem-calc(50 50 50 50);
            }
            &:nth-child(even) {
                .zigzag_content {
                    padding: rem-calc(50 50 50 50);
                }
            }
        }
        &.left {
            .zigzag_block {
                .zigzag_content {
                    padding: rem-calc(50 50 50 50);
                }
                &:nth-child(even) {
                    .zigzag_content {
                        padding: rem-calc(50 50 50 50);
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-landscape) {
        .zigzag_block {
            .zigzag_content {
                h2 {
                    font-size: rem-calc(34);
                    line-height: rem-calc(38);
                }
            }
        }
    }
    @include breakpoints(small) {
        .grid-container {
            padding: 0;
        }
        .zigzag_block {
            .side_img_div {
                position: relative;
                padding-top: 50%;
                max-width: 100%;
                width: 100%;
            }
            .zigzag_content {
                max-width: 100%;
                padding: rem-calc(30 20 30 20);
                h2 {
                    line-height: rem-calc(36);
                }
                ul {
                    li {
                        font-size: rem-calc(18);
                        line-height: rem-calc(23);
                        padding-left: rem-calc(15);
                        &:before {
                            top: rem-calc(8);
                        }
                    }
                }
                .btn {
                    font-size: rem-calc(16);
                    line-height: rem-calc(18);
                    padding: rem-calc(10 15 10 15);
                    margin: rem-calc(10 0 0 0);
                }
            }
            &:nth-child(even) {
                .zigzag_content {
                    margin-left: auto;
                    margin-right: rem-calc(0);
                    padding: rem-calc(30 20 30 20);
                }
            }
        }
        &.left {
            .zigzag_block {
                .zigzag_content {
                    padding: rem-calc(30 20 30 20);
                }
                &:nth-child(even) {
                    .zigzag_content {
                        padding: rem-calc(30 20 30 20);
                    }
                }
            }
        }
    }
}

.projects_sec {
    background-color: $dark_blue_12;
    padding: rem-calc(0 0 85 0);
    .cell {
        &.load_btn {
            margin: rem-calc(56 0 0 0);
        }
    }
    .feature_project_list {
        margin: 7.8% 0 0;
        @include breakpoints(small) {
            margin: rem-calc(30px 0 0 0);
        }
    }
    .more_projects {
        text-align: center;
        a {
            display: inline-block;
            vertical-align: top;
            span {
                display: block;
                text-transform: uppercase;
                font-weight: $bold;
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                color: $blue;
                letter-spacing: rem-calc(-0.1);
                margin-bottom: rem-calc(23);
            }
        }
    }
    @include breakpoints(small) {
        .cell {
            margin: rem-calc(40 0 0 0);
            &.load_btn {
                margin: rem-calc(50 0 0 0);
            }
        }
    }
}

.open_position_block {
    padding: rem-calc(102 0 100 0);
    .open_position_heading {
        text-align: center;
        h2 {
            color: $dark_blue;
            margin: rem-calc(0 0 15 0);
        }
        p {
            font-weight: $light;
            font-style: italic;
            font-size: rem-calc(21);
            line-height: rem-calc(31);
            color: $dark_blue;
        }
    }
    .open_position_list {
        max-width: rem-calc(960);
        margin: 0 auto;
        padding-top: rem-calc(67);
        .open_position_box {
            padding-right: rem-calc(10);
            position: relative;
            border-bottom: rem-calc(1) solid $yellow;
            padding-bottom: rem-calc(28);
            .more_link {
                position: absolute;
                right: 0;
                bottom: rem-calc(17);
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                color: $yellow;
                font-weight: $bold;
                text-transform: uppercase;
                padding-right: rem-calc(26);
                letter-spacing: rem-calc(0.3);
                &:before {
                    position: absolute;
                    content: "";
                    right: rem-calc(7);
                    top: rem-calc(7);
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid $yellow;
                    @include transition(all, 0.5s, ease-in-out);
                }
                &.active {
                    &:before {
                        top: rem-calc(5);
                        @include transform-rotate(180deg);
                    }
                }
            }
            h3 {
                font-weight: $light;
                font-size: rem-calc(33);
                line-height: rem-calc(40);
                color: $dark_blue;
                margin: rem-calc(0 0 8 0);
                letter-spacing: rem-calc(-0.1);
            }
            p {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
                color: $light_gray;
                font-weight: $regural;
                margin: rem-calc(0 0 20 0);
            }
            ul {
                li {
                    font-size: rem-calc(20);
                    line-height: rem-calc(30);
                    color: $light_gray;
                    font-weight: $regural;
                    &:before {
                        background-color: $light_gray;
                        width: rem-calc(5);
                        height: rem-calc(5);
                        top: rem-calc(11);
                    }
                }
            }
            ~ .open_position_box {
                margin: rem-calc(37 0 0 0);
            }
        }
    }
    @include breakpoints(small) {
        .open_position_list {
            .open_position_box {
                p {
                    font-size: rem-calc(18);
                    line-height: rem-calc(28);
                }
                ul {
                    li {
                        font-size: rem-calc(16);
                        line-height: rem-calc(20);
                        padding-left: rem-calc(15);
                        &:before {
                            top: rem-calc(7);
                        }
                    }
                }
            }
        }
    }
}

.content_area {
    padding: rem-calc(60 0);
}

.form_main {
    .gf_browser_ie.gform_wrapper .gform_footer input.button {
        padding: rem-calc(15 35 11 34);
    }
    .gform_wrapper ul.gform_fields li.gfield {
        margin: rem-calc(0 0 17 0);
        padding: 0;
        &:before {
            display: none;
        }
    }
    .gform_wrapper .top_label .gfield_label {
        display: none;
    }
    .gform_wrapper input {
        padding: 0;
        margin: 0;
        width: 100%;
        box-shadow: none;
        border: rem-calc(1) solid $gray2;
        height: rem-calc(40);
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .gform_wrapper .top_label input.medium {
        width: 100%;
    }
    .gform_wrapper textarea {
        padding: rem-calc(18);
        margin: 0;
        height: rem-calc(138) !important;
        width: 100%;
        box-shadow: none;
        border: rem-calc(1) solid $gray2;
        font-family: $proxima;
        font-weight: $bold;
        font-size: rem-calc(14);
        line-height: rem-calc(23);
        text-transform: uppercase;
        color: $gray3;
        letter-spacing: rem-calc(1.4);
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        padding: rem-calc(0 18);
        font-family: $proxima;
        font-weight: $bold;
        font-size: rem-calc(14);
        line-height: rem-calc(23);
        text-transform: uppercase;
        color: $gray3;
        letter-spacing: rem-calc(1.4);
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .gform_wrapper .gform_footer {
        margin: 0;
        padding: 0;
        text-align: right;
    }
    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_footer input[type=submit] {
        background-color: $blackcolor;
        border: 0;
        padding: rem-calc(15 35 11 34);
        height: auto;
        margin: 0;
        vertical-align: top;
        font-family: $proxima;
        font-weight: $bold;
        font-size: rem-calc(20);
        line-height: rem-calc(24) !important;
        text-transform: uppercase;
        color: $white;
        width: auto;
        outline: none;
        box-shadow: none !important;
        border-radius: 0 !important;
        &:hover {
            background-color: $yellow;
            color: $white;
        }
    }
    .gform_wrapper div.validation_error {
        display: none;
    }
    .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
        margin-top: 0;
    }
    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        width: 100% !important;
        border: 0;
        background-color: transparent;
        max-width: 100% !important;
    }
    .gform_wrapper.gform_validation_error .top_label input.medium {
        width: 100%;
        margin: 0;
    }
    .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
        margin-left: 0;
    }
    .gform_wrapper .top_label select.medium {
        width: 100%;
    }
    .gform_wrapper.gf_browser_chrome select {
        margin: 0;
        padding: rem-calc(0 18);
    }
    .gform_wrapper select {
        padding: rem-calc(0 24 0 18) !important;
        font-family: $proxima;
        font-weight: $bold;
        font-size: rem-calc(14);
        line-height: rem-calc(23);
        text-transform: uppercase;
        color: $gray3;
        letter-spacing: rem-calc(1.4);
        cursor: pointer;
        box-shadow: none;
        margin: 0;
        background-position: right center;
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .gform_wrapper select:focus {
        box-shadow: none;
    }
    .gform_wrapper.gform_validation_error .top_label select.medium {
        width: 100%;
    }
    .gform_wrapper .validation_message {
        color: $red;
        padding: rem-calc(0 0 5 0) !important;
        font-family: $proxima;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(20);
    }
    .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        border-color: $red;
    }
    .screen-reader-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        clip: auto;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
        input {
            cursor: pointer;
        }
    }
    .browse_btn {
        font-family: $proxima;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        position: absolute;
        right: rem-calc(8);
        top: rem-calc(7);
        background-color: $dark_gray;
        color: $blackcolor;
        padding: rem-calc(2 10 2 10);
        + .validation_message {
            padding: 0 !important;
        }
    }
    .gform_wrapper ul.gfield_checkbox li label{
        font-family: $proxima;
        font-size: rem-calc(17);
        line-height: rem-calc(20);
        font-weight: $regural;
        color: $white;
        padding: rem-calc(4 0 3 30);
        &:before{
            position: absolute;
            content: "";
            width: rem-calc(20);
            height: rem-calc(20);
            border: rem-calc(1) solid $white;
            left: 0;
            top: rem-calc(5);
        }
        &:after{
            position: absolute;
            content: "";
            background-image: url(../../../src/assets/images/check-arrow.png);
            width: rem-calc(0);
            height: rem-calc(19);
            left: rem-calc(3);
            top: rem-calc(3);
            @include transition(.5s, width, ease);
        }
        a{
            &:hover{
                color: $yellow;
            }
        }
    }
    .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label{
        font-weight: $regural;
    }
    .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label:after{
        width: rem-calc(19);
    }
    .gform_wrapper ul.gfield_checkbox li{
        position: relative;
    }
    .gform_wrapper ul.gfield_checkbox li input[type=checkbox]{
        margin: 0;
        opacity: 0;
        position: absolute;
        -webkit-appearance: none;
        border: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 9;
    }
}

body .form_main .gform_wrapper .top_label div.ginput_container {
    margin-top: 0;
}

.gform_confirmation_message {
    font-family: $proxima;
    font-weight: $regural;
    font-size: rem-calc(18);
    line-height: rem-calc(20);
    color: $green;
}

.contact_footer {
    .gform_confirmation_message {
        padding: rem-calc(150 0 150);
        @include breakpoints(small) {
            padding: rem-calc(60 0 60);
        }
    }
}

@media all and (-ms-high-contrast:none) {
    .main_banner_sec .main_banner_div .banner_video .slider-video {
        height: auto;
    }
}