
/*************************
Font Family
*/

$proxima: "proxima-nova";


/* ----- Font weight ------- */
$thin: 100;
$light: 300;
$regural: 400;
$medium: 500;
$bold: 700;
$extrabold: 800;
$black: 900;


/* ------------------ */

/* Color */

$gray: #58585b;
$gray_84: rgba(88, 88, 91, 0.84);
$gray2: #a9a8a9;
$gray3: #86878a;
$dark_gray: #e2e4e5;
$light_gray: #555559;
$white: #ffffff;
$blackcolor: #000000;
$blue: #002f86;
$dark_blue: #0c202a;
$dark_blue_12: rgba(12, 32, 42, 0.12);
$red: #f00000;
$yellow: #dba900;
$green: #1ee626;


/*************************
Mixins
*************************/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul,
li {
    padding: rem-calc(0);
    margin: rem-calc(0);
    list-style: none;
}
.clearfix {
    display: table;
    width: 100%;
    content: "";
}

/************ Transition *************/

@mixin transition($element, $time, $transition-name) {
    transition: $element $time $transition-name;
    -webkit-transition: $element $time $transition-name;
    -moz-transition: $element $time $transition-name;
    -ms-transition: $element $time $transition-name;
    -o-transition: $element $time $transition-name;
}

/************ Box shadow *************/

@mixin box-shadow($x, $y, $spread, $size, $color, $inset: "") {
    box-shadow: $x $y $spread $size $color #{$inset};
    -webkit-box-shadow: $x $y $spread $size $color #{$inset};
    -moz-box-shadow: $x $y $spread $size $color #{$inset};
    -ms-box-shadow: $x $y $spread $size $color #{$inset};
    -o-box-shadow: $x $y $spread $size $color #{$inset};
}

/************ Tranform Skew *************/

@mixin transform-skewY($valYdeg) {
    transform: skewY($valYdeg);
    -webkit-transform: skewY($valYdeg);
    -moz-transform: skewY($valYdeg);
    -ms-transform: skewY($valYdeg);
    -o-transform: skewY($valYdeg);
}

/************ Tranform Skew *************/

@mixin transform-skew($val1deg,$val2deg) {
    transform: skew($val1deg,$val2deg);
    -webkit-transform: skew($val1deg,$val2deg);
    -moz-transform: skew($val1deg,$val2deg);
    -ms-transform: skew($val1deg,$val2deg);
    -o-transform: skew($val1deg,$val2deg);
}


/************ Tranform rotate *************/

@mixin transform-rotate($deg1) {
    transform: rotate($deg1);
    -webkit-transform: rotate($deg1);
    -moz-transform: rotate($deg1);
    -ms-transform: rotate($deg1);
    -o-transform: rotate($deg1);
}

/************ Tranform scale *************/

@mixin transform-scale($deg) {
    transform: scale($deg);
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -ms-transform: scale($deg);
    -o-transform: scale($deg);
}

/************ Tranform Translate both *************/

@mixin transform-translate($valX,$valY) {
    transform: translate($valX,$valY);
    -webkit-transform: translate($valX,$valY);
    -moz-transform: translate($valX,$valY);
    -ms-transform: translate($valX,$valY);
    -o-transform: translate($valX,$valY);
}

/************ Tranform Translate Y *************/

@mixin transform-translate-ver($valY) {
    transform: translateY($valY);
    -webkit-transform: translateY($valY);
    -moz-transform: translateY($valY);
    -ms-transform: translateY($valY);
    -o-transform: translateY($valY);
}

/************ Tranform Translate X *************/

@mixin transform-translate-hor($valX) {
    transform: translateX($valX);
    -webkit-transform: translateX($valX);
    -moz-transform: translateX($valX);
    -ms-transform: translateX($valX);
    -o-transform: translateX($valX);
}

/************ Breakpoints *************/

@mixin breakpoints($breakpoint) {
    @if $breakpoint=="extremly-small" {
        @media(max-width: 450px) {
            @content;
        }
    }
    @if $breakpoint=="extra-small" {
        @media(max-width: 479px) {
            @content;
        }
    }
    @if $breakpoint=="x-small" {
        @media(max-width: 640px) {
            @content;
        }
    }
    @if $breakpoint=="small" {
        @media(max-width: 767px) {
            @content;
        }
    }
    @if $breakpoint=="min-tablet" { 
        @media(min-width: 767px) {
            @content;
        }
    }
    @if $breakpoint=="tablet" {
        @media(max-width: 768px) {
            @content;
        }
    }
    @if $breakpoint=="tablet-medium" {
        @media(max-width: 850px) {
            @content;
        }
    }
    @if $breakpoint=="tablet-portrait" {
        @media(max-width: 991px) {
            @content;
        }
    }
    @if $breakpoint=="tablet-landscape" {
        @media(max-width: 1023px) {
            @content;
        }
    }
    @if $breakpoint=="tablet-landscape-x" {
        @media(max-width: 1080px) {
            @content;
        }
    }
    @if $breakpoint=="extremly-large" {
        @media(max-width: 1100px) {
            @content;
        }
    }
    @if $breakpoint=="large" {
        @media(max-width: 1200px) {
            @content;
        }
    }
    @if $breakpoint=="x-large" {
        @media(max-width: 1300px) {
            @content;
        }
    }
    @if $breakpoint=="max-x-large" {
        @media(max-width: 1310px) {
            @content;
        }
    }
    @if $breakpoint=="min-x-large" {
        @media(min-width: 1366px) {
            @content;
        }
    }
    @if $breakpoint=="extra-large" {
        @media(max-width: 1400px) {
            @content;
        }
    }
    @if $breakpoint=="double-extra-large" {
        @media(min-width: 2000px) {
            @content;
        }
    }
}
a {
    text-decoration: none;
    @include transition(.5s, all, ease);
    color: inherit;
}
.cf:before,
.cf:after {
    display: table;

    content: "";
}
.cf:after {
    clear: both;
}
.cf {
    *zoom: 1;
}
p {
    margin: 0;
    font-family: $proxima;
    font-weight: $light;
    font-size: rem-calc(21);
    line-height: rem-calc(31);
    color: $dark_blue;
    @include breakpoints(tablet-portrait){
        br{
            display: none;
        }
    }
    @include breakpoints(small){
        font-size: rem-calc(18);
        line-height: rem-calc(28);
    }
}
ul{
    margin: rem-calc(15 0 15 0);
    li{
        font-family: $proxima;
        font-weight: $regural;
        color: $dark_blue;
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        padding: rem-calc(0 0 12 17);
        position: relative;
        letter-spacing: rem-calc(-0.4);
        &:before{
            position: absolute;
            content: "";
            width: rem-calc(6);
            height: rem-calc(6);
            border-radius: 50%;
            background-color: $blue;
            top: rem-calc(10);
            left: 0;
        }
        a{
            text-decoration: underline;
        }
        @include breakpoints(small){
            font-size: rem-calc(18);
            line-height: rem-calc(28);
        }
    }
}
ol{
    margin: rem-calc(0 0 0 20);
    li{
        font-family: $proxima;
        font-weight: $regural;
        color: $blue;
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        padding: rem-calc(0 0 12 5);
        list-style-type: decimal;
        letter-spacing: rem-calc(-0.4);
        @include breakpoints(small){
            font-size: rem-calc(18);
            line-height: rem-calc(28);
        }
    }
}
h3,
h4,
h5,
h6 {
    margin: 0;
    margin-bottom: rem-calc(0);
}
h1,
.h1 {
    font-family: $proxima;
    font-weight: $light;
    color: $dark_blue;
    font-size: rem-calc(43);
    line-height: rem-calc(52);
    letter-spacing: rem-calc(-0.2);
}
h2,
.h2 {
    font-family: $proxima;
    font-weight: $light;
    font-size: rem-calc(43);
    line-height: rem-calc(50);
    color: $dark_blue;
    letter-spacing: rem-calc(-0.2);
    @include breakpoints(small){
        font-size: rem-calc(32);
        line-height: rem-calc(34);
    }
}
h3,
.h3 {
    font-family: $proxima;
    font-weight: $bold;
    font-size: rem-calc(30);
    line-height: rem-calc(38);
}
h4,
.h4 {
    font-family: $proxima;
    font-weight: $regural;
    font-size: rem-calc(36);
    line-height: rem-calc(46);
}
h5,
.h5 {
    font-family: $proxima;
    font-weight: $bold;
    font-size: rem-calc(25);
    line-height: rem-calc(32);
}
h6,
.h6 {
    font-family: $proxima;
    font-weight: $bold;
    font-size: rem-calc(22);
    line-height: rem-calc(30);
}
.center-align {
    text-align: center;
}
.left-align {
    text-align: left;
}
.right-align {
    text-align: right;
}

.animation_fadeIn .animated, 
.animation_fadeInUp .animated,
.animation_fadeInDown .animated{  
    visibility: hidden; 
}
.animation_fadeIn .animated.fadeIn, 
.animation_fadeInUp .animated.fadeInUp,
.animation_fadeInDown .animated.fadeInDown { 
    visibility: visible;
}



input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
input::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
input:-ms-input-placeholder { /* IE 10+ */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
input:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
textarea::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
textarea:-ms-input-placeholder { /* IE 10+ */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}
textarea:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: $gray3;
    text-transform: uppercase;
}